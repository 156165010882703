<template>
  <!-- FOOTER & OVERLAYS & SCRIPTS -->
  <footer>
    <ul>
      <li>Last update: 06 February 2020</li>
      <li>&copy; 2020 Chevron Corporation</li>
      <li>Company Confidential</li>
      <li>OpCo: <a href="http://www.lagnr.chevron.com/">NMA SBU</a></li>
    </ul>
    <ul>
      <li>Content contact: <a href="mailto:nmaappsdev@chevron.com?subject=NMA%20NPA%20Vessel%20Movement%20Invoicing%20System">Application Development and Support Team</a></li>
      <li>Technical contact: <a href="mailto:nmaappsdev@chevron.com?subject=NMA%20NPA%20Vessel%20Movement%20Invoicing%20System">Application Development and Support Team</a></li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped lang="scss">
</style>

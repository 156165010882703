<template>
  <div class="welcome">
    <h4>
      <span>{{ msg }}</span>
    </h4>

  </div>

  <div style="position:absolute; width: 100% !important" class="homeImage">

    <img src="/src/assets/vessel1.jpg" alt="vessel image" />
  </div>

</template>
<script>
export default {
  name: 'Welcome',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h4{
    text-transform: lowercase;
}
</style>

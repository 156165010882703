import { bargeService } from '../../../services'

import Enums from '@/utils/enums'

import {
  FETCH_BARGES,
  CREATE_BARGE,
  UPDATE_BARGE,
  UPDATE_BARGE_STATUS
} from '../../action-type'
import {
  SET_BARGES,
  ADD_BARGE,
  EDIT_BARGE
} from '../../mutation-type'

export default {
  state: {
    barges: []
  },
  getters: {
    activeBarges: state => {
      return state.barges.filter(barge => barge.status === Enums.Status.Active.value)
    }
  },
  mutations: {
    [SET_BARGES](state, barges) {
      state.barges = [...barges]
    },
    [ADD_BARGE](state, barge) {
      state.barges.push(barge)
    },
    [EDIT_BARGE](state, barge) {
      if (state.barges.length) {
        let bargeIndex = state.barges.findIndex(x => x.id === barge.id)
        if (bargeIndex > -1) {
          state.barges.splice(bargeIndex, 1, barge)
        }
      }
    }
  },
  actions: {
    async [FETCH_BARGES]({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await bargeService.getAllBarges()
          if (result && result.data) {
            commit(SET_BARGES, result.data.items)
          }
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [CREATE_BARGE]({ commit }, barge) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await bargeService.createBarge(barge)
          barge.id = result.data.id
          commit(ADD_BARGE, barge)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [UPDATE_BARGE]({ commit }, barge) {
      return new Promise(async (resolve, reject) => {
        try {
          let result = await bargeService.updateBarge(barge)
          commit(EDIT_BARGE, result.data)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [UPDATE_BARGE_STATUS]({ commit }, barge) {
      return new Promise(async (resolve, reject) => {
        try {
          const newStatus = barge.status === Enums.Status.Active.value ? Enums.Status.Inactive.value : Enums.Status.Active.value
          let result = await bargeService.changeBargeStatus(barge.id, newStatus)
          //barge.status = modifiedBarge.status
          commit(EDIT_BARGE, result.data)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    }
  }
}

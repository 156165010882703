import Enums from '@/utils/enums'
import { userService, accountService } from '../../../services'

import {
  UPDATE_CURRENT_USER,
  FETCH_USERS,
  CREATE_USER,
  UPDATE_USER
} from '../../action-type'

import {
  SET_CURRENT_USER,
  SET_USERS,
  ADD_USER,
  EDIT_USER,
} from '../../mutation-type'

import { ClaimTypes } from '@/utils/constants'

export default {
  state: {
    accessToken: null,
    currentUser: {
      isAuthenticated: false,
      id: null,
      firstName: null,
      lastName: null,
      fullName: null,
      locationId: null,
      roleRights: null,
      isAdmin: false,
      doas: [],
      canViewMovementData: false,
      canEnterBaseMovementDetails: false,
      canEnterFuelDetails: false,
      canEnterCargoDetails: false,
      canSubmitMovement: false,
      canRunMonthEndProcess: false,
      canCancelMovementEntry: false,
      canRunReports: false,
      canRunReportsForAllLocations: false
    },
    users: []
  },
  getters: {
  },
  mutations: {
    [SET_CURRENT_USER](state, data) {
      if (data) {
        state.currentUser.isAuthenticated = true
        state.currentUser.id = data[ClaimTypes.employeeId]
        state.currentUser.firstName = data[ClaimTypes.firstName]
        state.currentUser.lastName = data[ClaimTypes.lastName]
        state.currentUser.fullName = data[ClaimTypes.name]
        state.currentUser.locationId = data[ClaimTypes.locationId] || null
        state.currentUser.roleRights = data[ClaimTypes.roleRights]
        state.currentUser.isAdmin = data[ClaimTypes.isAdmin]

        state.currentUser.canViewMovementData = (state.currentUser.roleRights & Enums.RoleRights.ViewMovementData.value) === Enums.RoleRights.ViewMovementData.value
        state.currentUser.canEnterBaseMovementDetails = (state.currentUser.roleRights & Enums.RoleRights.EnterBaseMovementDetails.value) === Enums.RoleRights.EnterBaseMovementDetails.value
        state.currentUser.canEnterFuelDetails = (state.currentUser.roleRights & Enums.RoleRights.EnterFuelDetails.value) === Enums.RoleRights.EnterFuelDetails.value
        state.currentUser.canEnterCargoDetails = (state.currentUser.roleRights & Enums.RoleRights.EnterCargoDetails.value) === Enums.RoleRights.EnterCargoDetails.value
        state.currentUser.canSubmitMovement = (state.currentUser.roleRights & Enums.RoleRights.SubmitMovement.value) === Enums.RoleRights.SubmitMovement.value
        state.currentUser.canRunMonthEndProcess = (state.currentUser.roleRights & Enums.RoleRights.RunMonthEndProcess.value) === Enums.RoleRights.RunMonthEndProcess.value
        state.currentUser.canCancelMovementEntry = (state.currentUser.roleRights & Enums.RoleRights.CancelMovement.value) === Enums.RoleRights.CancelMovement.value
        state.currentUser.canRunReports = (state.currentUser.roleRights & Enums.RoleRights.RunReports.value) === Enums.RoleRights.RunReports.value
        state.currentUser.canRunReportsForAllLocations = (state.currentUser.roleRights & Enums.RoleRights.RunReportsForAllLocations.value) === Enums.RoleRights.RunReportsForAllLocations.value
      }
      else {
        state.currentUser.isAuthenticated = false
        state.currentUser.id = null
        state.currentUser.firstName = null
        state.currentUser.lastName = null
        state.currentUser.isAdmin = null
      }
    },
    [SET_USERS](state, users) {
      state.users = [...users]
    },
    [ADD_USER](state, { user, rolesModule }) {
      const roles = rolesModule.roles.filter(r => r.id === user.roleId)
      if (roles && roles.length) {
        user.roleName = roles[0].name
      }
      state.users.push(user)
    },
    [EDIT_USER](state, { user, rolesModule }) {
      let index = null
      for (let i = 0; i < state.users.length; i++) {
        if (state.users[i].email === user.email) {
          index = i
          break
        }
      }
      const roles = rolesModule.roles.filter(r => r.id === user.roleId)
      if (roles && roles.length) {
        user.roleName = roles[0].name
      }
      state.users.splice(index, 1, user)
      //const users = state.users.filter((u) => u.id === user.id)
      //if (users && users.length) {
      //  const index = state.users.indexOf(users[0])
      //  state.users.splice(index, 1, user)
      //}
    }
  },
  actions: {
    async [UPDATE_CURRENT_USER]({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit(SET_CURRENT_USER, data)
        resolve()
      })
    },
    async [FETCH_USERS] ({ commit }, locationId) {
      return new Promise((resolve, reject) => {
        userService.getUsers(locationId).then((result) => {
          commit(SET_USERS, result.data)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [CREATE_USER] ({ commit, rootState }, user) {
      return new Promise((resolve, reject) => {
        userService.createUser(user).then((result) => {
          commit(ADD_USER, { user, rolesModule: rootState.roleModule })
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [UPDATE_USER]({ commit, rootState }, user) {
      return new Promise((resolve, reject) => {
        userService.updateUser(user).then((result) => {
          commit(EDIT_USER, { user, rolesModule: rootState.roleModule })
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    }
  }
}

import { rateService } from '../../../services'

import {
  FETCH_RATES,
  CREATE_RATE
} from '../../action-type'
import {
  SET_RATES,
  ADD_RATE
} from '../../mutation-type'

export default {
  state: {
    rates: []
  },
  getters: {
    allRates: state => {
      return state.rates
    }
  },
  mutations: {
    [SET_RATES](state, rates) {
      state.rates = [...rates]
    },
    [ADD_RATE](state, rate) {
      state.rates.push(rate)
    }
  },
  actions: {
    async [FETCH_RATES]({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.rates.length) {
          rateService.getAllRates().then((result) => {
            commit(SET_RATES, result.data.items)
            resolve()
          }).catch((error) => {
            reject(error)
          })
        } else {
          resolve()
        }
      })
    },
    async [CREATE_RATE]({ commit }, rate) {
      return new Promise((resolve, reject) => {
        rateService.createRate(rate).then((result) => {
          commit(ADD_RATE, result.data)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    }
  }
}

import { vesselTypeService } from '../../../services'

import Enums from '@/utils/enums'

import {
  FETCH_VESSELTYPES,
  CREATE_VESSELTYPE,
  UPDATE_VESSELTYPE,
  UPDATE_VESSELTYPE_STATUS
} from '../../action-type'
import {
  SET_VESSELTYPES,
  ADD_VESSELTYPE,
  EDIT_VESSELTYPE,
  CHANGE_VESSELTYPE_STATUS
} from '../../mutation-type'

export default {
  state: {
    vesselTypes: []
  },
  getters: {
    activeVesselTypes: state => {
      return state.vesselTypes.filter(vesselType => vesselType.status === Enums.Status.Active.value)
    }
  },
  mutations: {
    [SET_VESSELTYPES](state, vesselTypes) {
      state.vesselTypes = [...vesselTypes]
    },
    [ADD_VESSELTYPE](state, vesselType) {
      state.vesselTypes.push(vesselType)
    },
    [EDIT_VESSELTYPE](state, vesselType) {
      if (state.vesselTypes.length) {
        let vesselTypeIndex = state.vesselTypes.findIndex(x => x.id === vesselType.id)
        if (vesselTypeIndex > -1) {
          state.vesselTypes.splice(vesselTypeIndex, 1, vesselType)
        }
      }
    },
    [CHANGE_VESSELTYPE_STATUS](state, vesselType) {
      if (state.vesselTypes.length) {
        let matchingVesselType = state.vesselTypes.filter(x => x.id === vesselType.id)
        if (matchingVesselType && matchingVesselType.length) {
          matchingVesselType = matchingVesselType[0]
          matchingVesselType.status = vesselType.status
        }
      }
    }
  },
  actions: {
    async [FETCH_VESSELTYPES]({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await vesselTypeService.getAllVesselTypes()
          if (result && result.data) {
            commit(SET_VESSELTYPES, result.data.items)
          }
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [CREATE_VESSELTYPE]({ commit }, vesselType) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await vesselTypeService.createVesselType(vesselType)
          vesselType.id = result.data.id
          commit(ADD_VESSELTYPE, vesselType)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [UPDATE_VESSELTYPE]({ commit }, vesselType) {
      return new Promise(async (resolve, reject) => {
        try {
          await vesselTypeService.updateVesselType(vesselType)
          commit(EDIT_VESSELTYPE, vesselType)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [UPDATE_VESSELTYPE_STATUS]({ commit }, vesselType) {
      return new Promise(async (resolve, reject) => {
        try {
          const newStatus = vesselType.status === Enums.Status.Active.value ? Enums.Status.Inactive.value : Enums.Status.Active.value
          await vesselTypeService.changeVesselTypeStatus(vesselType.id, newStatus)
          vesselType.status = newStatus
          commit(CHANGE_VESSELTYPE_STATUS, vesselType)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    }
  }
}

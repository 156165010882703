'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/account`

export default class UserService extends BaseService {

  async getUsers (locationId) {
    return this.sendRequest('GET', `${serviceURL}/GetAllUsersForLocation/${locationId}`)
  }

  async createUser (user) {
    return this.sendRequest('POST', `${serviceURL}/Register`, user)
  }

  async getCurrenUser () {
    return this.sendRequest('GET', `${serviceURL}/current`)
  }

  async updateUser (user) {
    return this.sendRequest('PUT', `${serviceURL}`, user)
  }
}

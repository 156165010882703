import { roleService } from '../../../services'
import {
  FETCH_ROLES,
  CREATE_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE
} from '../../action-type'
import {
  SET_ROLES,
  ADD_ROLE,
  EDIT_ROLE,
  REMOVE_ROLE
} from '../../mutation-type'
import Enums from '@/utils/enums'

export default {
  state: {
    roles: []
  },
  getters: {
    activeRoles: state => {
      return state.roles.filter(role => role.status === Enums.Status.Active.value)
    }
  },
  mutations: {
    [SET_ROLES] (state, roles) {
      state.roles = [...roles]
    },
    [ADD_ROLE] (state, role) {
      state.roles.push(role)
    },
    [EDIT_ROLE] (state, role) {
      let index = null
      for (let i = 0; i < state.roles.length; i++) {
        if (state.roles[i].id === role.id) {
          index = i
          break
        }
      }
      state.roles.splice(index, 1, role)
    },
    [REMOVE_ROLE] (state, role) {
      let index = null
      for (let i = 0; i < state.roles.length; i++) {
        if (state.roles[i].id === role.id) {
          index = i
          break
        }
      }
      state.roles.splice(index, 1)
    }
  },
  actions: {
    async [FETCH_ROLES] ({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await roleService.getAllRoles()
          if (result && result.data) {
            commit(SET_ROLES, result.data.items)
          }
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [CREATE_ROLE] ({ commit }, role) {
      return new Promise((resolve, reject) => {
        roleService.createRole(role).then((result) => {
          role.id = result.data.id
          commit(ADD_ROLE, role)
          resolve(role)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [UPDATE_ROLE] ({ commit }, role) {
      return new Promise((resolve, reject) => {
        roleService.updateRole(role).then((result) => {
          commit(EDIT_ROLE, role)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [DELETE_ROLE] ({ commit }, role) {
      return new Promise((resolve, reject) => {
        roleService.deleteRole(role.id).then((result) => {
          commit(REMOVE_ROLE, role)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    }
  }
}

'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/vessels`

export default class VesselService extends BaseService {
  async getAllVessels() {
    return this.sendRequest('GET', `${serviceURL}`)
  }

  async createVessel(vessel) {
    return this.sendRequest('POST', `${serviceURL}`, vessel)
  }

  async updateVessel(vessel) {
    return this.sendRequest('PUT', `${serviceURL}`, vessel)
  }

  async changeVesselStatus(vesselId, newStatus) {
    return this.sendRequest('PUT', `${serviceURL}/changeStatus/${vesselId}/${newStatus}`)
  }
}

import { vesselService } from '../../../services'

import Enums from '@/utils/enums'

import {
  FETCH_VESSELS,
  CREATE_VESSEL,
  UPDATE_VESSEL,
  UPDATE_VESSEL_STATUS
} from '../../action-type'
import {
  SET_VESSELS,
  ADD_VESSEL,
  EDIT_VESSEL
} from '../../mutation-type'

export default {
  state: {
    vessels: []
  },
  getters: {
    activeVessels: state => {
      return state.vessels.filter(vessel => vessel.status === Enums.Status.Active.value)
    }
  },
  mutations: {
    [SET_VESSELS](state, vessels) {
      state.vessels = [...vessels]
    },
    [ADD_VESSEL](state, vessel) {
      state.vessels.push(vessel)
    },
    [EDIT_VESSEL](state, vessel) {
      if (state.vessels.length) {
        let vesselIndex = state.vessels.findIndex(x => x.id === vessel.id)
        if (vesselIndex > -1) {
          state.vessels.splice(vesselIndex, 1, vessel)
        }
      }
    }
  },
  actions: {
    async [FETCH_VESSELS]({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await vesselService.getAllVessels()
          if (result && result.data) {
            commit(SET_VESSELS, result.data.items)
          }
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [CREATE_VESSEL]({ commit }, vessel) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await vesselService.createVessel(vessel)
          vessel.id = result.data.id
          commit(ADD_VESSEL, vessel)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [UPDATE_VESSEL]({ commit }, vessel) {
      return new Promise(async (resolve, reject) => {
        try {
          let result = await vesselService.updateVessel(vessel)
          commit(EDIT_VESSEL, result.data)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [UPDATE_VESSEL_STATUS]({ commit }, vessel) {
      return new Promise(async (resolve, reject) => {
        try {
          const newStatus = vessel.status === Enums.Status.Active.value ? Enums.Status.Inactive.value : Enums.Status.Active.value
          let result = await vesselService.changeVesselStatus(vessel.id, newStatus)
          //vessel.status = modifiedVessel.status
          commit(EDIT_VESSEL, result.data)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    }
  }
}

import { ClaimTypes } from '@/utils/constants'

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export const isTokenExpired = (token) => {
  const parsedJWT = parseJwt(token)
  const tokenExpiry = new Date(0)
  tokenExpiry.setUTCSeconds(parsedJWT[ClaimTypes.expiry])
  let currentDate = new Date(Date.now())
  var timeDifferenceInSeconds = (tokenExpiry.getTime() - currentDate.getTime()) / 1000

  return timeDifferenceInSeconds < 15
}

'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/barges`

export default class BargeService extends BaseService {
  async getAllBarges() {
    return this.sendRequest('GET', `${serviceURL}`)
  }

  async createBarge(barge) {
    return this.sendRequest('POST', `${serviceURL}`, barge)
  }

  async updateBarge(barge) {
    return this.sendRequest('PUT', `${serviceURL}`, barge)
  }

  async changeBargeStatus(bargeId, newStatus) {
    return this.sendRequest('PUT', `${serviceURL}/changeStatus/${bargeId}/${newStatus}`)
  }
}

'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/publicHolidays`

export default class PublicHolidayService extends BaseService {
  async checkDateIsPublicHoliday(date) {
    return this.sendRequest('GET', `${serviceURL}/checkpublicholiday/${date}`)
  }
}

<template>
  <div id="app">
    <component v-bind:is="currentAppLayout"></component>
  </div>
</template>

<script>
import Layout12 from '@/components/layout/Layout12'
import Layout363 from '@/components/layout/Layout363'
import Layout39 from '@/components/layout/Layout39'
import Layout93 from '@/components/layout/Layout93'
import Layout66 from '@/components/layout/Layout66'

import { mapGetters } from 'vuex'

export default {
  components: {
    layout12: Layout12,
    layout363: Layout363,
    layout39: Layout39,
    layout93: Layout93,
    layout66: Layout66
  },
  computed: {
    ...mapGetters([
      'currentAppLayout'
    ])
  }
}

</script>

<style lang="scss">
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;
    a

  {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active

  {
    color: #42b983;
  }

  }
  }

  .btn-primary {
    color: #fff;
  }

  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
  }

  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130
  }

  .btn-danger.focus, .btn-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(225,83,97,.5)
  }

  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
  }

  .btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d
  }

  .btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(225,83,97,.5)
  }

  .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
  }

  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34
  }

  .btn-success.focus, .btn-success:focus {
    box-shadow: 0 0 0 .2rem rgba(72,180,97,.5)
  }

  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
  }

  .btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430
  }

  .btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(72,180,97,.5)
  }

  .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
  }

  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62
  }

  .btn-secondary.focus, .btn-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(130,138,145,.5)
  }

  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
  }

  .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b
  }

  .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(130,138,145,.5)
  }

  button[class*="btn-"]:after {
    content: '';
    display: inline-block;
    font-size: 16px;
    padding: 0 0 0 5px;
  }

  .modal {
    z-index: 1100
  }

  div.dataTables_wrapper div.dataTables_length select {
    min-width: 60px !important;
  }
</style>

import {
  UPDATE_ACCESS_TOKEN
} from '../../action-type'
import {
  SET_ACCESS_TOKEN
} from '../../mutation-type'

export default {
  state: {
    accessToken: null
  },
  mutations: {
    [SET_ACCESS_TOKEN] (state, accessToken) {
      state.accessToken = accessToken
    }
  },
  actions: {
    async [UPDATE_ACCESS_TOKEN] ({ commit }, accessToken) {
      return new Promise((resolve, reject) => {
        commit(SET_ACCESS_TOKEN, accessToken)
        resolve()
      })
    }
  }
}

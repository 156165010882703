import { userGroupService } from '../../../services'

import Enums from '@/utils/enums'

import {
  FETCH_USERGROUPS,
  CREATE_USERGROUP,
  UPDATE_USERGROUP,
  UPDATE_USERGROUP_STATUS
} from '../../action-type'
import {
  SET_USERGROUPS,
  ADD_USERGROUP,
  EDIT_USERGROUP
} from '../../mutation-type'

export default {
  state: {
    userGroups: []
  },
  getters: {
    activeUserGroups: state => {
      return state.userGroups.filter(userGroup => userGroup.status === Enums.Status.Active.value)
    }
  },
  mutations: {
    [SET_USERGROUPS](state, userGroups) {
      state.userGroups = [...userGroups]
    },
    [ADD_USERGROUP](state, userGroup) {
      state.userGroups.push(userGroup)
    },
    [EDIT_USERGROUP](state, userGroup) {
      if (state.userGroups.length) {
        let userGroupIndex = state.userGroups.findIndex(x => x.id === userGroup.id)
        if (userGroupIndex > -1) {
          state.userGroups.splice(userGroupIndex, 1, userGroup)
        }
      }
    }
  },
  actions: {
    async [FETCH_USERGROUPS]({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await userGroupService.getAllUserGroups()
          if (result && result.data) {
            commit(SET_USERGROUPS, result.data.items)
          }
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [CREATE_USERGROUP]({ commit }, userGroup) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await userGroupService.createUserGroup(userGroup)
          userGroup.id = result.data.id
          commit(ADD_USERGROUP, userGroup)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [UPDATE_USERGROUP]({ commit }, userGroup) {
      return new Promise(async (resolve, reject) => {
        try {
          let result = await userGroupService.updateUserGroup(userGroup)
          commit(EDIT_USERGROUP, result.data)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [UPDATE_USERGROUP_STATUS]({ commit }, userGroup) {
      return new Promise(async (resolve, reject) => {
        try {
          const newStatus = userGroup.status === Enums.Status.Active.value ? Enums.Status.Inactive.value : Enums.Status.Active.value
          let result = await userGroupService.changeUserGroupStatus(userGroup.id, newStatus)
          commit(EDIT_USERGROUP, result.data)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    }
  }
}

'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/userGroups`

export default class UserGroupService extends BaseService {
  async getAllUserGroups() {
    return this.sendRequest('GET', `${serviceURL}`)
  }

  async createUserGroup(userGroup) {
    return this.sendRequest('POST', `${serviceURL}`, userGroup)
  }

  async updateUserGroup(userGroup) {
    return this.sendRequest('PUT', `${serviceURL}`, userGroup)
  }

  async changeUserGroupStatus(userGroupId, newStatus) {
    return this.sendRequest('PUT', `${serviceURL}/changeStatus/${userGroupId}/${newStatus}`)
  }
}

export const Constants = {
  BASE_API_VERSION: 'v1'
}

export const Keys = {
  MS_GRAPH_CLIENT_ID: 'https://graph.windows.net',
  MS_GRAPH_URL: 'https://graph.windows.net/chevron.com/me?api-version=1.6'
}

export const ClaimTypes = {
  emailAddress: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
  firstName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
  lastName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
  name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  expiry: 'exp',
  locationId: 'locationId',
  roleRights: 'roleRights',
  isAdmin: 'isAdmin'
}

export const AccessTokenKey = 'accessToken'
export const RefreshTokenKey = 'refreshToken'

export const ALERT_TYPE_ERROR = 'error'
export const ALERT_TYPE_SUCCESS = 'success'
export const FLOATING_FIELD_TEXTINPUT = 'text'
export const FLOATING_FIELD_NUMBER = 'number'
export const FLOATING_FIELD_TEXTAREA = 'textarea'

export const CLOSE_MODAL_EVENT = 'CLOSE_MODAL'
export const CLOSE_MOVEMENT_CANCEL_MODAL_EVENT = 'CLOSE_MOVEMENT_CANCEL_MODAL_EVENT'

export const EMPLOYEE_SEARCH_STARTED_EVENT = 'EMPLOYEE_SEARCH_STARTED'
export const EMPLOYEE_SELECTED_EVENT = 'EMPLOYEE_SELECTED'
export const EMPLOYEE_SEARCH_CLEARED_EVENT = 'EMPLOYEE_SEARCH_CLEARED'
export const APPROVER_SEARCH_STARTED_EVENT = 'APPROVER_SEARCH_STARTED'
export const APPROVER_SELECTED_EVENT = 'APPROVER_SELECTED'
export const APPROVER_SEARCH_CLEARED_EVENT = 'APPROVER_SEARCH_CLEARED'

export const EDIT_USER_EVENT = 'EDIT_USER'
export const CHANGE_USER_STATUS_EVENT = 'CHANGE_USER_STATUS'

export const EDIT_ROLE_EVENT = 'EDIT_ROLE'
export const REMOVE_ROLE_EVENT = 'REMOVE_ROLE'

export const EDIT_LOCATION_EVENT = 'EDIT_LOCATION_EVENT'
export const CHANGE_LOCATION_STATUS_EVENT = 'CHANGE_LOCATION_STATUS_EVENT'

export const EDIT_VESSELTYPE_EVENT = 'EDIT_VESSELTYPE_EVENT'
export const CHANGE_VESSELTYPE_STATUS_EVENT = 'CHANGE_VESSELTYPE_STATUS_EVENT'

export const EDIT_BARGE_EVENT = 'EDIT_BARGE_EVENT'
export const CHANGE_BARGE_STATUS_EVENT = 'CHANGE_BARGE_STATUS_EVENT'

export const EDIT_USERGROUP_EVENT = 'EDIT_USERGROUP_EVENT'
export const CHANGE_USERGROUP_STATUS_EVENT = 'CHANGE_USERGROUP_STATUS_EVENT'

export const EDIT_VESSEL_EVENT = 'EDIT_VESSEL_EVENT'
export const CHANGE_VESSEL_STATUS_EVENT = 'CHANGE_VESSEL_STATUS_EVENT'

export const ADD_INWARDMOVEMENT_EVENT = 'ADD_INWARDMOVEMENT_EVENT'
export const EDIT_OUTWARDMOVEMENT_EVENT = 'EDIT_OUTWARDMOVEMENT_EVENT'
export const EDIT_INWARDMOVEMENT_EVENT = 'EDIT_INWARDMOVEMENT_EVENT'
export const EDIT_MOVEMENT_EVENT = 'EDIT_MOVEMENT_EVENT'
export const CANCEL_MOVEMENT_EVENT = 'CANCEL_MOVEMENT_EVENT'

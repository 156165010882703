'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/volumeCoefficientAndTonnage`

export default class VctService extends BaseService {
  async getAllVct() {
    return this.sendRequest('GET', `${serviceURL}`)
  }
}

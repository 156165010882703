<template>
  <div class="home">
    <!--<Welcome :msg="msg" />-->
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-6 mr-0">
            <div>
              <h3>Single Sign On (Chevron Internal User Only)</h3>
              <br />
              <button class="btn btn-primary d-inline" v-bind:class="{ spin: isPerformingSSO }" v-bind:disabled="isPerformingSSO || isPerformingUsernameSignIn" @click="performSingleSignOn">{{ ssoLoginText }}<span class="spinner"></span></button>
            </div>
          </div>
          <div class="col-6 mr-0">
            <div>
              <form>
                <fieldset class="p-4">
                  <h3>3rd Party Login</h3>
                  <input type="email" class="form-control mb-4" placeholder="Email" required />
                  <input type="password" class="form-control mb-4" placeholder="Password" required />
                  <div class="text-right">
                    <button class="btn btn-danger d-inline mr-2" v-bind:disabled = "isPerformingSSO">Cancel</button>
                    <button class="btn btn-primary d-inline mr-2" v-bind:disabled = "isPerformingSSO || isPerformingUsernameSignIn" v-bind:class="{ spin: isPerformingUsernameSignIn }">Login<span class="spinner"></span></button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  fieldset {
    border: 0.5px solid #cccccc;
    border-radius: 5px;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

</style>

<script>
  import store from '@/store'
  //import { mapState } from 'vuex'
  import { UPDATE_ACCESS_TOKEN, UPDATE_CURRENT_USER } from '@/store/action-type'

  import router from '@/router'

  import AdalAuthentication from '@/adal-auth'
  import { accountService } from '@/services'
  import { parseJwt } from '@/utils/accessTokenHelper'

  import { ClaimTypes, RefreshTokenKey } from '@/utils/constants'

  export default {
    name: 'home', components: {
    },
    data: function () {
      return {
        isPerformingUsernameSignIn: false,
        isPerformingSSO: false,
        ssoLoginText: 'Continue',
        usernameLoginText: 'Login'
      }
    },
    computed: {
    },
    methods: {
      performSingleSignOn() {
        this.isPerformingSSO = true
        this.isPerformingUsernameSignIn = false
        let tasks = []
        localStorage.removeItem(RefreshTokenKey)
        //tasks.push(store.dispatch(UPDATE_ACCESS_TOKEN, null))
        tasks.push(store.dispatch(UPDATE_CURRENT_USER, null))
        this.ssoLoginText = 'Authenticating...'
        Promise.all(tasks).then(() => {
          alert('sign in login')
          AdalAuthentication.acquireToken(process.env.VUE_APP_AZURE_APP_ID).then((token) => {
            return accountService.signIn()
          }).then((token) => {
            //localStorage.setItem(RefreshTokenKey, result.refreshToken)
            //let parsedJWT = parseJwt(result.token)
            //const d = new Date(0)
            //d.setUTCSeconds(parsedJWT[ClaimTypes.expiry])
            //console.log(d)
            //console.log(parsedJWT)
            //let tasks = []
            //tasks.push(store.dispatch(UPDATE_ACCESS_TOKEN, result.token))
            // tasks.push(store.dispatch(UPDATE_CURRENT_USER, parsedJWT))
            //Promise.all(tasks).then(() => {
            //  router.push('/')
            //})
            router.push('/')
          }).catch((error) => {
            console.log(error)
            showError(error)
          }).finally(() => {
            this.isPerformingSSO = false
            this.ssoLoginText = 'Continue'
          })
        })
      }
    },
    mounted() {
    }
  }

</script>

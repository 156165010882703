/* eslint-disable spaced-comment */
import store from '@/store'
import { UPDATE_ACCESS_TOKEN, UPDATE_CURRENT_USER } from '@/store/action-type'

import router from '@/router'

import axios from 'axios'
import { accountService } from '@/services'

import { ServerValidationError, AppError } from '@/utils/errors'

export default class BaseService {
  async sendRequest(httpMethod, url, data, additionalHeaders, responseType) {
    return accountService.getAccessToken().then((token) => {
      let headers = {
        'content-type': data && data.files ? 'multipart/form-data' : 'application/json',
        'Authorization': `Bearer ${token}`
      }

      for (const headerKey in additionalHeaders) {
        headers[headerKey] = additionalHeaders[headerKey]
      }

      if (data && data.files) {
        let formData = new FormData()
        for (const file of data.files) {
          formData.append(file.name, file.content)
        }
        delete data.files
        formData.append('data', JSON.stringify(data))
        data = formData
      }

      return new Promise((resolve, reject) => {
        axios.request({
          method: httpMethod,
          url,
          data,
          headers: headers,
          responseType: responseType || 'application/json'
        }).then((response) => {
          resolve(response)
        }).catch((error) => {
          console.log(error.response)
          if (error.response.data) {
            if (error.response.data.ResultCode) {
              reject(new AppError(error.response.data))
            } else if (error.response.status === 400) {
              reject(new ServerValidationError(error.response.data))
            } else {
              reject(new Error(error.response.data.detail))
            }
          } else {
            reject(new Error(error))
          }
        })
      })
    })
    //  .catch(() => {
    //  alert('catching')
    //  try {
    //    let tasks = []
    //    tasks.push(store.dispatch(UPDATE_ACCESS_TOKEN, null))
    //    tasks.push(store.dispatch(UPDATE_CURRENT_USER, null))
    //    router.push('/login')
    //  } catch (err) {
    //  }
    //})
  }
}

import { movementService } from '../../../services'

import {
  FETCH_MOVEMENTS,
  FETCH_MOVEMENTS_PENDING_USER_ACTION,
  CREATE_MOVEMENT,
  UPDATE_FUEL_DATA,
  UPDATE_CARGO_DATA,
  SUBMIT_MOVEMENT
} from '../../action-type'
import {
  CLEAR_MOVEMENTS,
  CLEAR_MOVEMENTS_PENDING_USER_ACTION,
  SET_MOVEMENTS,
  SET_MOVEMENTS_PENDING_USER_ACTION,
  ADD_MOVEMENT,
  EDIT_MOVEMENT
} from '../../mutation-type'

export default {
  state: {
    movements: [],
    movementsPendingUserAction: []
  },
  getters: {
  },
  mutations: {
    [CLEAR_MOVEMENTS] (state) {
      state.movements.splice(0, state.movements.length)
    },
    [CLEAR_MOVEMENTS_PENDING_USER_ACTION] (state) {
      state.movements.splice(0, state.movementsPendingUserAction.length)
    },
    [SET_MOVEMENTS] (state, movements) {
      state.movements = [...movements]
    },
    [SET_MOVEMENTS_PENDING_USER_ACTION] (state, movements) {
      state.movementsPendingUserAction = [...movements]
    },
    [ADD_MOVEMENT] (state, movement) {
      state.movements.push(movement)
    },
    [EDIT_MOVEMENT] (state, data) {
      const movements = state.movements.filter((v) => v.id === data.id)
      if (movements && movements.length) {
        const index = state.movements.indexOf(movements[0])
        state.movements.splice(index, 1, data)
      }
    }
  },
  actions: {
    async [FETCH_MOVEMENTS]({ commit, state }, params) {
      return new Promise(async (resolve, reject) => {
        try {
          commit(CLEAR_MOVEMENTS)
          const result = await movementService.getAllMovementsForLocation(params)
          if (result && result.data) {
            commit(SET_MOVEMENTS, result.data.items)
          }
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [FETCH_MOVEMENTS_PENDING_USER_ACTION]({ commit, state }, params) {
      return new Promise(async (resolve, reject) => {
        try {
          commit(CLEAR_MOVEMENTS_PENDING_USER_ACTION)
          const result = await movementService.getMovementPendingUser(params)
          if (result && result.data) {
            commit(SET_MOVEMENTS_PENDING_USER_ACTION, result.data.items)
          }
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [CREATE_MOVEMENT] ({ commit }, movement) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await movementService.createMovement(movement)
          commit(ADD_MOVEMENT, result.data)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [UPDATE_FUEL_DATA] ({ commit, state }, fuelDatarequest) {
      return new Promise((resolve, reject) => {
        movementService.updateFuelData(fuelDatarequest).then((result) => {
          commit(EDIT_MOVEMENT, result.data)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [UPDATE_CARGO_DATA] ({ commit, state }, cargoDatarequest) {
      return new Promise((resolve, reject) => {
        movementService.updateCargoData(cargoDatarequest).then((result) => {
          commit(EDIT_MOVEMENT, result.data)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async [SUBMIT_MOVEMENT]({ commit, state }, request) {
      return new Promise((resolve, reject) => {
        movementService.submitMovement(request).then((result) => {
          commit(EDIT_MOVEMENT, result.data)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
  }
}

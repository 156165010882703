'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/monthEnd`

export default class MonthEndService extends BaseService {
  async run (monthAndYear) {
    let url = `${serviceURL}/run/${monthAndYear}`
    return this.sendRequest('POST', url)
  }
}

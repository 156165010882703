'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/chargeCodes`

export default class ChargeCodeService extends BaseService {
  async searchChargeCodes (searchString) {
    return this.sendRequest('GET', `${serviceURL}/search/${searchString}`)
  }
}

'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/employees`

export default class EmployeeService extends BaseService {
  async searchEmployees (searchString) {
    return this.sendRequest('GET', `${serviceURL}/search/${searchString}`)
  }

  async getEmployee(employeeId) {
    return this.sendRequest('GET', `${serviceURL}/${employeeId}`)
  }

  async getEmployeeWithWorkType(employeeId) {
    return this.sendRequest('GET', `${serviceURL}/${employeeId}?getWorkType=true`)
  }

  async getEmployeeYearsOfContinousService(employeeId) {
    let result = await this.sendRequest('GET', `${serviceURL}/${employeeId}/yearsOfCOntinousService`)
    return result.data
  }

  async getEmployeeDOAs(employeeNo) {
    return this.sendRequest('GET', `${serviceURL}/${employeeNo}/doas/`)
  }

  async getEmployeeDependants(employeeId) {
    return this.sendRequest('GET', `${serviceURL}/${employeeId}/dependants`)
  }

  async getEmployeeLocationHistory(employeeId) {
    let result = await this.sendRequest('GET', `${serviceURL}/${employeeId}/locationHistory`)
    return result.data
  }

  async getEmployeeWorkScheduleHistory(employeeId) {
    let result = await this.sendRequest('GET', `${serviceURL}/${employeeId}/workScheduleHistory`)
    return result.data
  }
}

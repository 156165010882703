export const SET_ACCESS_TOKEN = 'setAccessToken'
export const SET_USER_ATHENTICATION_STATUS = 'setUserAuthenticationStatus'

export const SET_APPSETTINGS = 'setAppSettings'
export const SET_CURRENT_USER = 'setCurrentUser'

export const SET_ROLES = 'setRoles'
export const ADD_ROLE = 'addRole'
export const EDIT_ROLE = 'editRole'
export const REMOVE_ROLE = 'removeRole'

export const SET_USERS = 'setUsers'
export const ADD_USER = 'addUser'
export const EDIT_USER = 'editUser'

export const SET_VESSELTYPES = 'setVesselTypes'
export const ADD_VESSELTYPE = 'addVesselType'
export const EDIT_VESSELTYPE = 'editVesselType'
export const CHANGE_VESSELTYPE_STATUS = 'changeVesselTypeStatus'

export const SET_VESSELS = 'setVessels'
export const ADD_VESSEL = 'addVessel'
export const EDIT_VESSEL = 'editVessel'
export const CHANGE_VESSEL_STATUS = 'changeVesselStatus'

export const SET_BARGES = 'setBarges'
export const ADD_BARGE = 'addBarge'
export const EDIT_BARGE = 'editBarge'
export const CHANGE_BARGE_STATUS = 'changeBargeStatus'

export const SET_USERGROUPS = 'setUserGroups'
export const ADD_USERGROUP = 'addUserGroup'
export const EDIT_USERGROUP = 'editUserGroup'
export const CHANGE_USERGROUP_STATUS = 'changeUserGroupStatus'

export const SET_LOCATIONS = 'setLocations'
export const ADD_LOCATION = 'addLocation'
export const EDIT_LOCATION = 'editLocation'
export const CHANGE_LOCATION_STATUS = 'changeLocationStatus'

export const SET_RATES = 'setRates'
export const ADD_RATE = 'addRate'

export const SET_VCT = 'setVct'
export const ADD_VCT = 'addVct'

export const CLEAR_MOVEMENTS = 'clearMovements'
export const CLEAR_MOVEMENTS_PENDING_USER_ACTION = 'clearMovementsPendingUserAction'
export const SET_MOVEMENTS = 'setMovements'
export const SET_MOVEMENTS_PENDING_USER_ACTION = 'setMovementsPendingUserAction'
export const ADD_MOVEMENT = 'addMovement'
export const EDIT_MOVEMENT = 'editMovement'

export const SET_LAYOUT = 'setLayout'

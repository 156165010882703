'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/reports`
const searchServiceURL = `/api/${Constants.BASE_API_VERSION}/movements`

export default class ReportService extends BaseService {
  async search (searchParams) {
    const locationId = searchParams.locationId || ''
    const vesselId = searchParams.vesselId || ''
    const bargeId = searchParams.bargeId || ''
    const mode = searchParams.mode === 0 ? 0 : searchParams.mode || ''
    const reason = searchParams.reason === 0 ? 0 : searchParams.reason || ''
    const status = searchParams.status === 0 ? 0 : searchParams.status || ''
    const userGroupId = searchParams.userGroupId || ''
    const chargeCode = searchParams.chargeCode || ''
    const url = `${serviceURL}/search?locationId=${locationId}&vesselId=${vesselId}&bargeId=${bargeId}&userGroupId=${userGroupId}&mode=${mode}&reason=${reason}&status=${status}&chargeCode=${chargeCode}`
    let result = await this.sendRequest('GET', encodeURI(url))
    return result.data
  }

  async searchMovement (searchParams) {
    const from = searchParams.from || ''
    const to = searchParams.to || ''
    const locationId = searchParams.locationId || ''
    const vesselId = searchParams.vesselId || ''
    const bargeId = searchParams.bargeId || ''
    const mode = searchParams.mode === 0 ? 0 : searchParams.mode || ''
    const reason = searchParams.reason === 0 ? 0 : searchParams.reason || ''
    const status = searchParams.status === 0 ? 0 : searchParams.status || ''
    const userGroupId = searchParams.userGroupId || ''
    const chargeCode = searchParams.chargeCode || ''
    const url = `${searchServiceURL}/search?from=${from}&to=${to}&locationId=${locationId}&vesselId=${vesselId}&bargeId=${bargeId}&userGroupId=${userGroupId}&mode=${mode}&reason=${reason}&status=${status}&chargeCode=${chargeCode}`
    let result = await this.sendRequest('GET', encodeURI(url))
    return result.data
  }
  async searchMovements (searchParams) {
    const query = Object.entries(searchParams)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')

    const url = `${searchServiceURL}/GetBySearch?${query}`
    let result = await this.sendRequest('GET', url)
    return result.data
  }

  async getMovementsPendingUser() {
    return this.sendRequest('GET', `${searchServiceURL}/pendingMe`)
  }

}

import { locationService } from '../../../services'

import Enums from '@/utils/enums'

import {
  FETCH_LOCATIONS,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  UPDATE_LOCATION_STATUS
} from '../../action-type'
import {
  SET_LOCATIONS,
  ADD_LOCATION,
  EDIT_LOCATION
} from '../../mutation-type'

export default {
  state: {
    locations: []
  },
  getters: {
    activeLocations: state => {
      return state.locations.filter(location => location.status === Enums.Status.Active.value)
    },
    terminalLocations: state => {
      return state.locations.filter(location => location.isTerminal)
    },
    activeTerminalLocations: state => {
      return state.locations.filter(location => location.status === Enums.Status.Active.value && location.isTerminal)
    }
  },
  mutations: {
    [SET_LOCATIONS](state, locations) {
      state.locations = [...locations]
    },
    [ADD_LOCATION](state, location) {
      state.locations.push(location)
    },
    [EDIT_LOCATION](state, location) {
      if (state.locations.length) {
        let locationIndex = state.locations.findIndex(x => x.id === location.id)
        if (locationIndex > -1) {
          state.locations.splice(locationIndex, 1, location)
        }
      }
    }
  },
  actions: {
    async [FETCH_LOCATIONS] ({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await locationService.getAllLocations()
          if (result && result.data) {
            commit(SET_LOCATIONS, result.data.items)
          }
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [CREATE_LOCATION] ({ commit }, location) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await locationService.createLocation(location)
          location.id = result.data.id
          commit(ADD_LOCATION, location)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [UPDATE_LOCATION] ({ commit }, location) {
      return new Promise(async (resolve, reject) => {
        try {
          let result = await locationService.updateLocation(location)
          commit(EDIT_LOCATION, result.data)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    },
    async [UPDATE_LOCATION_STATUS]({ commit }, location) {
      return new Promise(async (resolve, reject) => {
        try {
          const newStatus = location.status === Enums.Status.Active.value ? Enums.Status.Inactive.value : Enums.Status.Active.value
          let result = await locationService.changeLocationStatus(location.id, newStatus)
          commit(EDIT_LOCATION, result.data)
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    }
  }
}

'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/locations`

export default class LocationService extends BaseService {
  async getAllLocations () {
    return this.sendRequest('GET', `${serviceURL}`)
  }

  async createLocation (location) {
    return this.sendRequest('POST', `${serviceURL}`, location)
  }

  async updateLocation (location) {
    return this.sendRequest('PUT', `${serviceURL}`, location)
  }

  async changeLocationStatus (locationId, newStatus) {
    return this.sendRequest('PUT', `${serviceURL}/changeStatus/${locationId}/${newStatus}`)
  }
}

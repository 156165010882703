'use strict'

import store from '@/store'
import { UPDATE_CURRENT_USER } from '@/store/action-type'

import AdalAuthentication from '@/adal-auth'

import axios from 'axios'

import { Constants, AccessTokenKey, RefreshTokenKey } from '@/utils/constants'
import { parseJwt, isTokenExpired } from '@/utils/accessTokenHelper'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/account`
const tokenServiceURL = `/api/${Constants.BASE_API_VERSION}/token`

export default class AccountService {
  async signIn() {
    const token = await AdalAuthentication.acquireToken(process.env.VUE_APP_AZURE_APP_ID)
    let headers = {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token.idToken}`
    }
    try {
      let response = await axios.request({
        method: 'POST',
        url: `${serviceURL}/signIn`,
        headers: headers,
        responseType: 'json'
      })
      const result = response.data
      localStorage.setItem(RefreshTokenKey, result.refreshToken)
      localStorage.setItem(AccessTokenKey, result.token)
      let parsedJWT = parseJwt(result.token)
      return store.dispatch(UPDATE_CURRENT_USER, parsedJWT)
    }
    catch (error) {
      return new Promise((resolve, reject) => {
        return reject(error)
      })
    }
  }

  async getAccessToken (iteration = 0) {
    let token = localStorage.getItem(AccessTokenKey)
    if (!token) {
      return new Promise((resolve, reject) => {
        reject(new Error('access token not found'))
      })
    }
    if (isTokenExpired(token)) {
      if (iteration) {
        return new Promise((resolve, reject) => {
          return reject(new Error('unable to refresh token'))
        })
      }
      try {
        var response = await this.tryRefreshToken()
        const result = response.data
        localStorage.setItem(RefreshTokenKey, result.refreshToken)
        localStorage.setItem(AccessTokenKey, result.token)
        let parsedJWT = parseJwt(result.token)
        await store.dispatch(UPDATE_CURRENT_USER, parsedJWT)
        return new Promise((resolve, reject) => {
          return resolve(result.token)
        })
      }
      catch (error) {
        localStorage.removeItem(RefreshTokenKey)
        let tasks = []
        tasks.push(store.dispatch(UPDATE_CURRENT_USER, null))
        return Promise.all(tasks)
      }
    }
    else {
      return new Promise((resolve, reject) => {
        return resolve(token)
      })
    }
  }

  async tryRefreshToken () {
    let headers = {
      'content-type': 'application/json'
    }
    const accessToken = localStorage.getItem(AccessTokenKey)
    const refreshToken = localStorage.getItem(RefreshTokenKey)
    if (!accessToken || !refreshToken) {
      return new Promise((resolve, reject) => {
        return reject(new Error('access token or request token not found'))
      })
    }
    return axios.request({
      method: 'POST',
      url: `${tokenServiceURL}/refresh`,
      headers: headers,
      data: {
        accessToken,
        refreshToken
      },
      responseType: 'json'
    })
  }
}

import { SET_LAYOUT } from '@/store/mutation-type'

export default {
  state: {
    currentAppLayout: 'layout12'
  },
  mutations: {
    [SET_LAYOUT] (state, layout) {
      state.currentAppLayout = layout
    }
  },
  getters: {
    currentAppLayout (state) {
      return state.currentAppLayout
    }
  }
}

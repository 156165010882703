export const UPDATE_ACCESS_TOKEN = 'updateAccessToken'
export const UPDATE_USER_ATHENTICATION_STATUS = 'updateUserAuthenticationStatus'

export const FETCH_APPSETTINGS = 'fetchAppSettings'

export const UPDATE_CURRENT_USER = 'updateCurrentUser'

export const FETCH_ROLES = 'fetchRoles'
export const CREATE_ROLE = 'createRole'
export const UPDATE_ROLE = 'updateRole'
export const DELETE_ROLE = 'deleteRole'

export const FETCH_USERS = 'fetchUsers'
export const CREATE_USER = 'createUser'
export const UPDATE_USER = 'updateUser'
export const CHANGE_USER_STATUS = 'changeUserStatus'

export const FETCH_VESSELTYPES = 'fetchVesselTypes'
export const CREATE_VESSELTYPE = 'createVesselType'
export const UPDATE_VESSELTYPE = 'updateVesselType'
export const UPDATE_VESSELTYPE_STATUS = 'updateVesselTypeStatus'

export const FETCH_VESSELS = 'fetchVessels'
export const CREATE_VESSEL = 'createVessel'
export const UPDATE_VESSEL = 'updateVessel'
export const UPDATE_VESSEL_STATUS = 'updateVesselStatus'

export const FETCH_BARGES = 'fetchBarges'
export const CREATE_BARGE = 'createBarge'
export const UPDATE_BARGE = 'updateBarge'
export const UPDATE_BARGE_STATUS = 'updateBargeStatus'

export const FETCH_USERGROUPS = 'fetchUserGroups'
export const CREATE_USERGROUP = 'createUserGroups'
export const UPDATE_USERGROUP = 'updateUserGroupType'
export const UPDATE_USERGROUP_STATUS = 'updateUserGroupStatus'

export const FETCH_LOCATIONS = 'fetchLocations'
export const CREATE_LOCATION = 'createLocation'
export const UPDATE_LOCATION = 'updateLocation'
export const UPDATE_LOCATION_STATUS = 'updateLocationStatus'

export const FETCH_RATES = 'fetchRates'
export const CREATE_RATE = 'createRate'

export const FETCH_VCT = 'fetchVct'
export const CREATE_VCT = 'createVct'

export const FETCH_MOVEMENTS = 'fetchMovements'
export const FETCH_MOVEMENTS_PENDING_USER_ACTION = 'fetchMovementsPendingUserAction'
export const CREATE_MOVEMENT = 'createMovements'
export const UPDATE_FUEL_DATA = 'updateFuelData'
export const UPDATE_CARGO_DATA = 'updateCargoData'
export const SUBMIT_MOVEMENT = 'submitMovement'

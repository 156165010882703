import Vue from 'vue'

import App from './App.vue'
import Error from './Error.vue'

import router from './router'
import store from './store'
import { UPDATE_CURRENT_USER } from '@/store/action-type'

import eventBus from '@/utils/eventBus'
import AdalAuthentication from '@/adal-auth'

import { accountService } from '@/services'
import { parseJwt } from '@/utils/accessTokenHelper'

Vue.config.productionTip = false
//Vue.config.devtools = false

const showApp = (error) => {
  new Vue({
    router,
    store,
    data: {
      eventBus //: new Vue()
    },
    provide() {
      return {
        eventBus: this.eventBus
      }
    },
    render: h => h(error ? Error : App)
  }).$mount('#app')
}

accountService.getAccessToken()
  .then((token) => {
    let parsedJWT = parseJwt(token)
    store.dispatch(UPDATE_CURRENT_USER, parsedJWT).then(() => {
      showApp()
    })
  })
  .catch((error) => {
    console.log(error)
    AdalAuthentication.initialize().then((account) => {
      /*alert('init done')*/
      if (account) {
        accountService.signIn().then(() => {
          /*alert('Signed In')*/
          showApp()
        }).catch((error) => {
          /*alert(error)*/
          showApp(error)
        })
      } else {
        showApp(new Error())
      }
    })
  })

    //  .then(() => {
    //  alert('Initialized')
    //  if (AdalAuthentication.isAuthenticated()) {
    //    alert('Authenticated')
    //    accountService.signIn().then(() => {
    //      alert('Signed In')
    //      showApp()
    //    }).catch(() => {
    //      alert('wahala wa o 2')
    //      showApp()
    //    })
    //  }
    //  else {
    //    alert('Show App')
    //    showApp()
    //  }
    //}).catch(() => {
    //  alert('wahala wa o 1')
    //  showApp()
    //})
  //})

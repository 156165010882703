'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/vesselTypes`

export default class VesselTypeService extends BaseService {
  async getAllVesselTypes() {
    return this.sendRequest('GET', `${serviceURL}`)
  }

  async createVesselType (vesselType) {
    return this.sendRequest('POST', `${serviceURL}`, vesselType)
  }

  async updateVesselType(vesselType) {
    return this.sendRequest('PUT', `${serviceURL}`, vesselType)
  }

  async changeVesselTypeStatus(vesselTypeId, newStatus) {
    return this.sendRequest('PUT', `${serviceURL}/changeStatus/${vesselTypeId}/${newStatus}`)
  }
}

<template>
  <nav v-if="currentUser.isAuthenticated" id="site-specific-nav">
    <!-- #region site-specific navigation -->
    <ul>
      <li>
        <a href="#movements">Movements</a>
        <ul>
          <li><router-link to="/movements">Manage movements</router-link></li>
          <li><router-link to="/movements/pending">Pending my action</router-link></li>
          <li v-if="currentUser.canRunMonthEndProcess"><router-link to="/movements/monthEnd">Generate movements data</router-link></li>
        </ul>
      </li>
      <li>
        <router-link to="/movements/report/monthly">Pilotage Cost</router-link>
        <!--<a href="#report" v-if="currentUser.canRunReports || currentUser.canRunReportsForAllLocations">report</a>
        <ul>
          <li><router-link to="/movements/report/monthly">monthly report</router-link></li>
          <li><router-link to="/movements/report/search">search movements</router-link></li>
        </ul>-->
      </li>
      <li>
        <a v-if="showAdminMenu" href="#admin">Admin</a>
        <ul>
          <li v-if="currentUser.isAdmin || currentUser.canManageUsers"><router-link to="/admin/users">user administration</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canManageRoles"><router-link to="/admin/roles">role administration</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canManageVesselTypes"><router-link to="/admin/vesselTypes">manage vessel types</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canManageVessels"><router-link to="/admin/vessels">manage vessels</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canManageBarges"><router-link to="/admin/barges">manage barges</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canManageUserGroups"><router-link to="/admin/userGroups">manage user group</router-link></li>
          <li v-if="currentUser.isAdmin || currentUser.canManageLocations"><router-link to="/admin/locations">manage locations</router-link></li>
          <!--<li v-if="currentUser.isAdmin || currentUser.canManageRoles"><router-link to="/admin/vessels">manage vessels</router-link></li>-->
        </ul>
      </li>
    </ul>
    <!-- #endregion site-specific navigation -->
  </nav>
</template>

<script>

  import store from '@/store'
  import { mapState } from 'vuex'

  export default {
    name: 'AppNavigation',
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
      }),
      showAdminMenu() {
        return this.currentUser.isAdmin || this.currentUser.canManageUsers || this.currentUser.canManageRoles || this.currentUser.canManageVesselTypes
          || this.currentUser.canManageVessels || this.currentUser.canManageBarges || this.currentUser.canManageUserGroups || this.currentUser.canManageLocations
      }
    }
  }
</script>

<style scoped lang="scss">
</style>

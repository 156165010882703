import {
  APPROVE_REQUEST,
  REJECT_REQUEST,
  RETURN_REQUEST,
  CANCEL_REQUEST
} from '@/store/action-type'

const RequestState = {
  New: 0,
  Submitted: 1
}

const Status = {
  Inactive: {
    value: 0,
    description: 'Not Active',
  },
  Active: {
    value: 1,
    description: 'Active'
  }
}


const Months = {
  January: {
    value: 1,
    description: 'Jan'
  },
  February: {
    value: 2,
    description: 'Feb'
  },
  March: {
    value: 3,
    description: 'Mar'
  },
  April: {
    value: 4,
    description: 'Apr'
  },
  May: {
    value: 5,
    description: 'May'
  },
  June: {
    value: 6,
    description: 'Jun'
  },
  July: {
    value: 7,
    description: 'Jul'
  },
  August: {
    value: 8,
    description: 'Aug'
  },
  September: {
    value: 9,
    description: 'Sep'
  },
  October: {
    value: 10,
    description: 'Oct'
  },
  November: {
    value: 11,
    description: 'Nov'
  },
  December: {
    value: 12,
    description: 'Dec'
  }
}

const RoleRights = {
  ManageRoles: {
    value: 1,
    description: 'Manage roles'
  },
  ManageUsers: {
    value: 2,
    description: 'Manage users'
  },
  ViewMovementData: {
    value: 4,
    description: 'View movement data'
  },
  EnterBaseMovementDetails: {
    value: 8,
    description: 'Enter base movement details'
  },
  EnterFuelDetails: {
    value: 16,
    description: 'Enter fuel details'
  },
  EnterCargoDetails: {
    value: 32,
    description: 'Enter cargo details'
  },
  SubmitMovement: {
    value: 64,
    description: 'Submit movement'
  },
  RunMonthEndProcess: {
    value: 128,
    description: 'Run month end process'
  },
  CancelMovement: {
    value: 256,
    description: 'Cancel movement'
  },
  RunReports: {
    value: 512,
    description: 'Run reports'
  },
  RunReportsForAllLocations: {
    value: 1024,
    description: 'Run reports for all locations'
  }
}

const MovementModes = {
  Departure: {
    value: 0,
    description: 'Departure'
  },
  Arrival: {
    value: 1,
    description: 'Arrival'
  }
}

const MovementReasons = {
  OffshoreOperations: {
    value: 0,
    description: 'Offshore Operations'
  },
  WarriConvoy: {
    value: 1,
    description: 'Warri Convoy'
  },
  EscravosConvoy: {
    value: 2,
    description: 'Escravos Convoy'
  },
  OnneConvoy: {
    value: 3,
    description: 'Onne Convoy'
  },
  ChannelPatrol: {
    value: 4,
    description: 'Channel Patrol'
  },
  DiveOperations: {
    value: 5,
    description: 'Dive Operations'
  },
  DrillingOperations: {
    value: 6,
    description: 'Drilling Operations'
  },
  EGTLOperations: {
    value: 7,
    description: 'EGTL Operations'
  },
  ERCOperations: {
    value: 8,
    description: 'ERC Operations'
  },
  FuelSupply: {
    value: 9,
    description: 'Fuel Supply'
  },
  InspectionAndInvestigation: {
    value: 10,
    description: 'Inspection & Investigation'
  },
  PerimeterPatrol: {
    value: 11,
    description: 'Perimeter Patrol'
  },
  SalvageAndRescueOperation: {
    value: 12,
    description: 'Salvage & Rescue Operation'
  },
  Survey: {
    value: 13,
    description: 'Survey'
  },
  SouthSwampConvoy: {
    value: 14,
    description: 'South Swamp Convoy'
  },
  SeaTrial: {
    value: 15,
    description: 'Sea Trial'
  },
  RiverClinic: {
    value: 16,
    description: 'River Clinic'
  },
  SGCPatrol: {
    value: 17,
    description: 'SGC Patrol'
  },
  MooringOperations: {
    value: 18,
    description: 'Mooring Operations'
  },
  NorthSwampConvoy: {
    value: 19,
    description: 'North Swamp Convoy'
  },
  OffshoreSupply: {
    value: 20,
    description: 'Offshore Supply'
  },
  OpenRiver: {
    value: 21,
    description: 'Open River'
  },
  Others: {
    value: 21,
    description: 'Others'
  }
}

const MovementStatus = {
  Undefined: {
    value: 0,
    description: 'not yet submitted'
  },
  AwaitingFuelAnalyst: {
    value: 1,
    description: 'awaiting fuel analyst'
  },
  AwaitingCargoAnalyst: {
    value: 2,
    description: 'awaiting cargo analyst'
  },
  AwaitingAnalystReview: {
    value: 3,
    description: 'Awaiting analyst review'
  },
  Submitted: {
    value: 4,
    description: 'Submitted'
  },
  BillGnerated: {
    value: 5,
    description: 'Bill Generated'
  },
  Cancelled: {
    value: 6,
    description: 'Cancelled'
  },
  Calculated: {
    value: 7,
    description: 'Calculated'
  }
}

class Enums {
  static convertToEnum(enumType, value) {
    for (const enumProperty in enumType) {
      if (enumType[enumProperty].value === value) {
        return enumType[enumProperty]
      }
    }
    return null
  }
}

Object.freeze(RequestState)
Object.freeze(Status)
Object.freeze(Months)
Object.freeze(RoleRights)
Object.freeze(MovementModes)
Object.freeze(MovementReasons)
Object.freeze(MovementStatus)

Object.defineProperty(Enums, 'RequestState', {
  value: RequestState,
  writable: false
})

Object.defineProperty(Enums, 'Status', {
  value: Status,
  writable: false
})

Object.defineProperty(Enums, 'Months', {
  value: Months,
  writable: false
})

Object.defineProperty(Enums, 'RoleRights', {
  value: RoleRights,
  writable: false
})

Object.defineProperty(Enums, 'MovementModes', {
  value: MovementModes,
  writable: false
})

Object.defineProperty(Enums, 'MovementReasons', {
  value: MovementReasons,
  writable: false
})

Object.defineProperty(Enums, 'MovementStatus', {
  value: MovementStatus,
  writable: false
})

Object.freeze(Enums)

export default Enums

import { vctService } from '../../../services'

import Enums from '@/utils/enums'

import {
  FETCH_VCT
} from '../../action-type'
import {
  SET_VCT,
  ADD_VCT
} from '../../mutation-type'

export default {
  state: {
    vct: []
  },
  getters: {
    activeVCT: state => {
      return state.vct
    }
  },
  mutations: {
    [SET_VCT](state, vct) {
      state.vct = [...vct]
    },
    [ADD_VCT](state, vct) {
      state.vct.push(vct)
    }
  },
  actions: {
    async [FETCH_VCT] ({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await vctService.getAllVct()
          if (result && result.data) {
            commit(SET_VCT, result.data.items)
          }
          resolve()
        }
        catch (error) {
          reject(error)
        }
      })
    }
  }
}

import AppSettingsService from './appSettingsService'
import EmployeeService from './employeeService'
import UserService from './userService'
import AccountService from './accountService'
import RoleService from './roleService'
import VesselTypeService from './vesselTypeService'
import VesselService from './vesselService'
import VctService from './vctService'
import BargeService from './bargeService'
import UserGroupService from './userGroupService'
import LocationService from './locationService'
import RateService from './rateService'
import MovementService from './movementService'
import PublicHolidayService from './publicHolidayService'
import ChargeCodeService from './chargeCodeService'
import MonthEndService from './monthEndService'
import ReportService from './reportService'

export const appSettingsService = new AppSettingsService()
export const employeeService = new EmployeeService()
export const userService = new UserService()
export const accountService = new AccountService()
export const roleService = new RoleService()
export const vesselTypeService = new VesselTypeService()
export const vesselService = new VesselService()
export const vctService = new VctService()
export const bargeService = new BargeService()
export const userGroupService = new UserGroupService()
export const locationService = new LocationService()
export const rateService = new RateService()
export const movementService = new MovementService()
export const monthEndService = new MonthEndService()
export const publicHolidayService = new PublicHolidayService()
export const chargeCodeService = new ChargeCodeService()
export const reportService = new ReportService()

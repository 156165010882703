import Vue from 'vue'
import Vuex from 'vuex'

import AccessTokenModule from '@/store/modules/accessToken'
import AppSettingsModule from '@/store/modules/appSettings'
import LayoutModule from '@/store/modules/layout'
import UserModule from '@/store/modules/user'
import RoleModule from '@/store/modules/role'
import VesselTypeModule from '@/store/modules/vesselType'
import VesselModule from '@/store/modules/vessel'
import BargeModule from '@/store/modules/barge'
import UserGroupModule from '@/store/modules/userGroup'
import LocationModule from '@/store/modules/location'
import RateModule from '@/store/modules/rate'
import MovementModule from '@/store/modules/movement'
import VctModule from '@/store/modules/vct'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    applicationSettingsModule: AppSettingsModule,
    layout: LayoutModule,
    userModule: UserModule,
    roleModule: RoleModule,
    vesselTypeModule: VesselTypeModule,
    vesselModule: VesselModule,
    bargeModule: BargeModule,
    userGroupModule: UserGroupModule,
    locationModule: LocationModule,
    rateModule: RateModule,
    movementModule: MovementModule,
    vctModule: VctModule,
    accessTokenModule: AccessTokenModule
  }
})

<template>
  <div class="home">
    <Welcome :msg="msg" />

    <img src="https://i.ytimg.com/vi/NYNoFGzzHLo/maxresdefault.jpg" width="100%" />

  </div>
  
</template>

<script>
import { mapState } from 'vuex'
// @ is an alias to /src
import Welcome from '@/components/Welcome.vue'

export default {
  name: 'home',
  components: {
    Welcome
  },
  data: function () {
    return {
      msg: null
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userModule.currentUser
    })
  },
  mounted () {
    const currentDate = new Date()
    const hours = currentDate.getHours()
    const timeOfDay = hours < 12 ? 'morning' : (hours < 16 ? 'afternoon' : 'evening')
    this.msg = `Good ${timeOfDay}, ${this.currentUser.firstName}`
  }
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import { accountService } from '@/services'

import { SET_LAYOUT } from '@/store/mutation-type'

import Home from '@/views/Home.vue'
import LoginView from '@/views/Login.vue'
const MovementView = () => import(/* webpackChunkName: "movementViews" */ '@/views/movement/index')
const MonthEndGenerationView = () => import(/* webpackChunkName: "movementViews" */ '@/views/movement/monthEnd')
const MonthlyMovementReportView = () => import(/* webpackChunkName: "movementViews" */ '@/views/movement/monthlyReport')
const PendingActionView = () => import(/* webpackChunkName: "movementViews" */ '@/views/movement/pendingUser')
const RoleView = () => import(/* webpackChunkName: "adminViews" */ '@/views/admin/role')
const UserView = () => import(/* webpackChunkName: "adminViews" */ '@/views/admin/user')
const VesselTypeAdminView = () => import(/* webpackChunkName: "adminViews" */ '@/views/admin/vesselType')
const VesselAdminView = () => import(/* webpackChunkName: "adminViews" */ '@/views/admin/vessel')
const BargeAdminView = () => import(/* webpackChunkName: "adminViews" */ '@/views/admin/barge')
const UserGroupAdminView = () => import(/* webpackChunkName:    "adminViews" */ '@/views/admin/userGroup')
const LocationAdminView = () => import(/* webpackChunkName: "adminViews" */ '@/views/admin/location')
const MovementSearchReportView = () => import(/* webpackChunkName: "reportViews" */ '@/views/reports/search')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/movements',
    name: 'movements',
    component: MovementView
  //  meta: { requiredRights: 'canManageUsers' }
  },
  {
    path: '/movements/monthEnd',
    name: 'monthEndGeneration',
    component: MonthEndGenerationView,
    meta: { requiredRights: ['canRunMonthEndProcess'] }
  },
  {
    path: '/movements/report/monthly',
    name: 'monthlyMovementsReport',
    component: MonthlyMovementReportView
  },
  {
    path: '/movements/pending',
    name: 'movementsPendingendingAction',
    component: PendingActionView
  },
  {
    path: '/admin/users',
    name: 'users',
    component: UserView,
    meta: { requiredRights: ['canManageUsers', 'isAdmin'] }
  },
  {
    path: '/admin/roles',
    name: 'roles',
    component: RoleView,
    meta: { requiredRights: ['canManageRoles', 'isAdmin'] }
  },
  {
    path: '/admin/vesselTypes',
    name: 'vesselTypesAdmin',
    component: VesselTypeAdminView,
    meta: { requiredRights: ['canManageVesselTypes', 'isAdmin'] }
  },
  {
    path: '/admin/vessels',
    name: 'vesselAdmin',
    component: VesselAdminView,
    meta: { requiredRights: ['canManageVessels', 'isAdmin'] }
  },
  {
    path: '/admin/barges',
    name: 'bargeAdmin',
    component: BargeAdminView,
    meta: { requiredRights: ['canManageBarges', 'isAdmin'] }
  },
  {
    path: '/admin/userGroups',
    name: 'userGroupAdmin',
    component: UserGroupAdminView,
    meta: { requiredRights: ['canManageUserGroups', 'isAdmin'] }
  },
  {
    path: '/admin/locations',
    name: 'locationAdmin',
    component: LocationAdminView,
    meta: { requiredRights: ['canManageLocations', 'isAdmin'] }
  },
  {
    path: '/movements/report/search',
    name: 'movementSearchReport',
    component: MovementSearchReportView
    /*meta: { requiredRights: ['canRunReports', 'isAdmin'] }*/
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const setLayout = function (to) {
  let layout = 'layout12'
  if (to.matched.some(record => record.meta.layout)) {
    layout = to.matched[0].meta.layout
  }
  if (layout !== store.state.layout.currentLayout) {
    store.commit(SET_LAYOUT, layout)
  }
}

router.beforeEach(async (to, from, next) => {
  setLayout(to)
  if (to.path === '/' || to.path === '/login') {
    next()
  }
  else {
    try {
      await accountService.getAccessToken()
      if (to.matched.some(record => record.meta.requiredRights)) {
        for (const right of to.matched[0].meta.requiredRights) {
          if (store.state.userModule.currentUser[right]) {
            next()
            return
          }
        }
      }
      else {
        next()
        return
      }
      next({
        path: '/login'
      })
    }
    catch (error) {
      next({
        path: '/login'
      })
    }
  }
})


export default router

'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/rates`

export default class RateService extends BaseService {
  async getAllRates() {
    return this.sendRequest('GET', `${serviceURL}`)
  }

  async createRate(rate) {
    return this.sendRequest('POST', `${serviceURL}`, rate)
  }
}
